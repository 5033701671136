var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teacher"},[_c('list-template',{attrs:{"total":_vm.total,"table-data":_vm.tableData,"table-config":_vm.tableConfig,"current-page":_vm.page,"loading":_vm.loading,"hasAdd":""},on:{"onChangePage":_vm.changeCurrentPage,"onHandle":_vm.tableHandle,"onAdd":function($event){return _vm.$router.push('./add')}}}),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v("删除后学生将不能设置学习目标！")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("点错了")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.confirm}},[_vm._v("仍然删除")])],1)]),_c('el-dialog',{attrs:{"visible":_vm.detailsShow,"width":"30%"},on:{"update:visible":function($event){_vm.detailsShow=$event}}},[_c('el-descriptions',{attrs:{"column":1,"labelStyle":{
            'width': '196rem',
            'justify-content': 'flex-end',
            'padding-right': '10rem',
            'line-height': '40rem',
          },"contentStyle":{
             'line-height': '40rem'
          }}},[_c('el-descriptions-item',{attrs:{"label":"校区"}},[_vm._v(_vm._s(_vm.detailsData.school_name))]),_c('el-descriptions-item',{attrs:{"label":"年级"}},[_vm._v(_vm._s(_vm.detailsData.grade_name))]),_c('el-descriptions-item',{attrs:{"label":"学生开始填写时间"}},[_vm._v(_vm._s(_vm.detailsData.start_at))]),_c('el-descriptions-item',{attrs:{"label":"学生结束填写时间"}},[_vm._v(_vm._s(_vm.detailsData.end_at))]),_c('el-descriptions-item',{attrs:{"label":"创建人"}},[_vm._v(_vm._s(_vm.detailsData.creator))]),_c('el-descriptions-item',{attrs:{"label":"创建时间"}},[_vm._v(_vm._s(_vm.detailsData.created_at))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }