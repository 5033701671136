<template>
  <div class="teacher">
    <list-template
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :current-page="page"
        :loading="loading"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        hasAdd
        @onAdd="$router.push('./add')"
    ></list-template>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <span>删除后学生将不能设置学习目标！</span>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">点错了</el-button>
				<el-button type="primary" :loading="btnLoading" @click="confirm">仍然删除</el-button>
			</span>
    </el-dialog>
    <el-dialog :visible.sync="detailsShow" width="30%">
      <el-descriptions
          :column="1"
          :labelStyle="{
            'width': '196rem',
            'justify-content': 'flex-end',
            'padding-right': '10rem',
            'line-height': '40rem',
          }"
          :contentStyle="{
             'line-height': '40rem'
          }">
        <el-descriptions-item label="校区">{{ detailsData.school_name }}</el-descriptions-item>
        <el-descriptions-item label="年级">{{ detailsData.grade_name }}</el-descriptions-item>
        <el-descriptions-item label="学生开始填写时间">{{ detailsData.start_at }}</el-descriptions-item>
        <el-descriptions-item label="学生结束填写时间">{{ detailsData.end_at }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{ detailsData.creator }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ detailsData.created_at }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from "moment";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
    ...mapState(['page'])
  },
  data() {
    return {
      loading:false,
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '名称',
        },
        {
          prop: 'school_name',
          label: '校区',
        },
        {
          prop: 'grade_name',
          label: '年级',
        },
        {
          prop: 'start_at',
          label: '开始填写时间'
        },
        {
          prop: 'end_at',
          label: '结束填写时间'
        },
        {
          prop: 'target_exam_id',
          label: '是否关联考试',
          render: (row) => {
            return row.target_exam_id ? '是' : '否'
          }
        },
        {
          label: '操作',
          handle: true,
          width: 120,
          buttons: [{ type:"view", text:"查看" }]
        }
      ],
      btnLoading: false,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],
      // 删除宿舍的id
      dormitoryId: '',

      detailsShow: false,
      detailsData: '',
      detailsBtnLoading: false
    }
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let { page } = this
      this.loading = true;
      this.$_axios2.get('api/student/goal-setting', {params: { page }}).then(res => {
        let data = res.data.data
        if(!(Array.isArray(data))){
          this.tableData = data.list
          this.total = data.page.total
        }else{
          this.tableData = []
        }
      }).finally(()=>this.loading = false);
    },

    // 表格后面操作被点击
    tableHandle(row, handle) {
      const { type } = handle
      if (type === 'view') {
        this.$router.push('./details?id=' + row.id)
      }
    },

    // 弹窗确定按钮
    confirm() {
      this.btnLoading = true
      this.$_axios2.delete('api/student/goal-setting/'+ this.dormitoryId +'/').then(res => {
        if(res.data.status === 0){
          this.$message.success({
            message: res.data.msg,
            onClose: () => {
              this.dialogVisible = false
              this.getData()
            }
          })
        }
      }).finally(()=>{
        this.btnLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;
  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }
  .pagination {
    margin-top: 50rem;
  }
}
</style>
